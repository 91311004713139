.more-info{
    width: 100vw;
    padding-top: 80px;
    padding-bottom: 80px;
}
.bg-1{
    background-color: var(--white-color);
}
.bg-2{
    background-color: var(--super-light-grey-color);
}
.bg-3{
    background-color: var(--white-color);
}
.more-info-title{
    text-align: center;
}
.more-info-card{
    width: 90%;
    max-width: 1000px;
    background-color: var(--white-color);
    border-radius: 5px;
    box-shadow: 1px 1px 5px #888888;
    margin: auto;
    margin-top: 20px;
    text-align: center; 
}
.more-info-picture{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    max-height: 350px;
    height: 100%;
    text-align: center;
    object-fit: cover;
}
.text-col{
    padding-top: 15px;
}
.picture-col{
    padding-right: 0px;
    padding-left: 0px;
}

@media screen and (max-width: 767px) {
    .picture-col{
        order: 1;
    }
    .text-col{
        order: 2;
    }
    .more-info-picture{
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 0px;
        width: 100%;
        max-height: 200px;
        text-align: center;
        object-fit: cover;
    }
}
@media screen and (max-width: 500px) {
    .bg-2, .bg-3{
        padding-top: 10px;
    }
}