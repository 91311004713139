/* COLORS */
:root {
  --white-color: white;
  --black-color: black;
  --blue-color: #2761BA;
  --dark-blue-color: #153270;
  --light-blue-color: #2A89E1;
  --grey-color: #5D6B78;
  --dark-grey-color: #313941;
  --light-grey-color: #90989E;
  --super-light-grey-color: #F5F6F7;
  --seperator-grey-color: #E2E5E8;


  --fudget-f-color: #3889E1;
  --fudget-u-color: #75DB3B;
  --fudget-d-color: #FFE634;
  --fudget-g-color: #8981F0;
  --fudget-e-color: #D45954;
  --fudget-t-color: #5CB928;
}
/* CSS IMPORTS */
@import "missingcontent.css"; 
@import "contact.css";
@import "order.css";
@import "moreinfo.css";
@import "admin.css";

/* FONTS */
@font-face {
  font-family: "FudgetFont";
  src: local('ARLRDBD'), url("../Fonts/ARLRDBD.ttf") format("TrueType");
}

/* ROOT */
body{
  font-family: "Open Sans",Arial,sans-serif;
  overflow-x: hidden;
  background-color: var(--dark-grey-color);
}
::placeholder { 
  color: var(--light-grey-color);
  opacity: 1; 
}
.row{
  margin-right: 0px;
  margin-left: 0px;
}
a:hover{
  text-decoration: none;
}
.card-col{
  margin-bottom: 20px;
}

/* TEXT */

h1{
  color: var(--dark-grey-color);
  font-weight: bold;
  font-size: 60px;
  transition: ease-in;
}

h2{
  color: var(--dark-grey-color);
  font-weight: bold;
  font-size: 50px;
  text-align: left;
}

h3{
  color: var(--grey-color);
  font-size: 33px;
  text-align: center;
  margin-top: 33px;
}

h4{
  color: var(--light-grey-color);
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  line-height: 1.3;
  padding-left: 10vw;
  padding-right: 10vw;
}

h5{
  color: var(--dark-grey-color);
  font-size: 20px;
  margin-top: 20px;
  font-weight: bold;
}
h6{
  color: var(--light-grey-color);
  font-size: 16px;
  margin-top: 20px;
  padding-left: 15%;
  padding-right: 15%;
}


/* NAVBAR */
.navbar{
  z-index: 110;
  height: 80px;
  width: 100vw;
  background-color: var(--white-color);
  position: fixed;
  overflow: hidden;
  padding: 0px;
  left: 0px;
  top: 0px;
  text-align: center;
  justify-content: flex-end;
}
.navbar-logo{
  height: 40px;
  margin-left: 20px;
  margin-right: auto;
  cursor: pointer;
}

.navbar-list {
  list-style-type: none;
}

.navbar-item{
  float: left;
}

.navbar-item .n-item {
  color: var(--dark-grey-color);
  display: block;
  text-decoration: none;
  font-size: 15px;
  padding-top: 16px;
  margin-right: 30px;
  margin-bottom: 0px;
  cursor: pointer;
}
.navbar-item .n-item:hover {
  color: var(--blue-color);
}
.hamburger-button{
  display: none;
  font-size: 20px;
  cursor: pointer;
}

.navbar-button{
  background-color: var(--blue-color);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 40px;
  cursor: pointer;
}
.navbar-button:hover{
  opacity: 0.9;
}
.navbar-button-text{
  list-style-type: none;
  color: var(--white-color);
  font-size: 15px;
  text-decoration: none;
  display: block;
  pointer-events: none;
  margin-bottom: 0px;
}

.sidebar{
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 110;
  top: 0;
  right: 0;
  background-color: var(--dark-grey-color);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 10px;
}

.sidebar-button{
  background-color: var(--blue-color);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  width: 190px;
  cursor: pointer;
  white-space: nowrap; 
  text-overflow: clip; 
}

.sidebar-button-text{
  list-style-type: none;
  color: var(--white-color);
  margin-bottom: 0px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  pointer-events: none;
}
.sidebar-button:hover{
  opacity: 0.9;
}

.sidebar-list {
  list-style-type: none;
}

.sidebar-item .s-item {
  margin-bottom: 0px;
  color: var(--white-color);
  display: block;
  text-decoration: none;
  font-size: 15px;
  padding-top: 16px;
  margin-left: -20px;
  white-space: nowrap; 
  text-overflow: clip; 
}
.sidebar-item .s-item:hover {
  color: var(--blue-color);
}

/* HEADER */

.header {
  margin-top:80px;
  background-color: var(--white-color);
  padding-bottom: 40px;
}
.header-text{
  padding-left: 8vw;
  padding-top: 13vh;
}

.header-text2 {
  margin-top: 20px;
  text-align: left;
  padding-left: 0vw;
  padding-right: 10vw;
}
.header-picture{
  height: 50vw;
  max-height: 600px;
  min-height: 400px;
  padding-top: 7vh;
  padding-left: 5vw;
}
.header-form-group{
  margin-top: 20px;
}
.header-input{
  width: 60%;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: var(--light-grey-color);
  border: 1px solid var(--light-grey-color);
  border-radius: 5px;
  margin-right: 10px;
}
.header-button{
  background-color: var(--blue-color);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 42px;
  cursor: pointer;
  width: auto;
}
.header-button:hover{
  opacity: 0.9;
}
.header-button-text{
  list-style-type: none;
  color: var(--white-color);
  display: inline;
  font-size: 15px;
  text-decoration: none;
  display: block;
  pointer-events: none;
}


/* INFO */

.info{
  width: 100vw;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--super-light-grey-color);
}
.separator-box {
  width: 100vw;
  align-items: center;
  justify-content: center;
  
}
.separator-line {
  margin: auto;
  width: 120px;
  height: 8px;
  border-radius: 20px;
  background-color: var(--blue-color);
}
.info-card {
  width: 90%;
  background-color: var(--white-color);
  border-radius: 5px;
  box-shadow: 1px 1px 5px #888888;
  margin: auto;
  margin-top: 80px;
  text-align: center;
}

.info-icon-box-1{
  background-color: var(--fudget-g-color);
}
.info-icon-box-2{
  background-color: var(--fudget-u-color);
}
.info-icon-box-3{
  background-color: var(--fudget-e-color);
}
.info-picture{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: auto;
  text-align: center;
}
.info-height{
  height: 170px;
}
.card-seperator{
  width: 100%;
  height: 1px;
  background-color: var(--seperator-grey-color);
  margin-top: 40px;
}
.info-link{
  color: var(--blue-color);
  padding: 20px;
  cursor: pointer;
  font-size: 15px;
}
.info-link:hover{
  opacity: 0.9;
}

/* FUNCTIONS */

.function{
  background-color: var(--white-color);
  width: 100vw;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: end;
}
.function-title{
  margin-bottom: 40px;
}
.function-picture{
  width: 70%;
  max-width: 400px;
}
.function-text-row{
  margin-top: 15px;
  text-align: left;
}
.function-text{
  text-align: left;
  color: var(--grey-color);
  font-size: 20px;
}
.fa-check{
  color: var(--blue-color);
  font-size: 30px;
}
.iphone-frame{
  position: absolute;
  top: -22px;
  left: 478px;
  width: 315px;
  z-index: 101;
}
.carousel{
  left: 260px;
  width: 200px;
}
.control-arrow{
  z-index: 102 !important;
}
.control-dots{
  padding-left: 0px;
}
.dot{
  margin: 0 3px !important;
}


/* PRICE */

.price{
  width: 100vw;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--super-light-grey-color);
}
.price-card {
  width: 90%;
  background-color: var(--white-color);
  border-radius: 5px;
  box-shadow: 1px 1px 5px #888888;
  margin: auto;
  margin-top: 80px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.price-name {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0px;
}
.silver{
  background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(116,116,116,1) 50%, rgba(238,238,238,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gold{
  background: linear-gradient(0deg, rgba(238,238,39,1) 0%, rgba(161,144,0,1) 50%, rgba(238,238,39,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.platinum{
  background: linear-gradient(0deg, rgba(154,218,255,1) 0%, rgba(42,177,255,1) 50%, rgba(154,218,255,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.price-desc {
  font-size: 16px;
  color: var(--light-grey-color);
}
.price-seperator{
    width: 90%;
    height: 1px;
    background-color: var(--seperator-grey-color);
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.price-price{
  font-size: 45px;
  color: var(--dark-grey-color);
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: -20px
}
.price-month{
  font-size: 15px;
  color: var(--light-grey-color);
  margin-top: -10px;
}
.price-price-item{
  font-size: 15px;
  color: var(--grey-color);
  line-height: 0.7;
}
.price-item{
  font-size: 17px;
  color: var(--grey-color);
}
.price-button{
  background-color: var(--blue-color);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 15%;
  margin-right: 15%;
  height: 40px;
  cursor: pointer;
}
.price-button:hover{
  opacity: 0.9;
}
.price-button-text{
  list-style-type: none;
  color: var(--white-color);
  display: inline;
  padding-top: 8px;
  font-size: 17px;
  text-decoration: none;
  display: block;
  pointer-events: none;
}

/* ABOUT US */
.about{
  background-color: var(--white-color);
  width: 100vw;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: end;
}
.about-title{
  margin-bottom: 40px;
  text-align: left;
}
.about-picture{
  width: 70%;
  max-width: 400px;
}
.about-text-row{
  margin-top: 15px;
  padding-right: 20%
}
.about-text{
  text-align: left;
  color: var(--grey-color);
  font-size: 20px;
}
.about-person-name{
  text-align: left;
  color: var(--dark-grey-color-color);
  font-size: 24px;
  margin-bottom: 0px;
}
.about-person-title{
  text-align: left;
  color: var(--grey-color);
  font-size: 16px;
}

/* CONTACT */

.contact{
  width: 100vw;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--super-light-grey-color);
}
.contact-us{
  margin-top: 80px;
}
.name-input{
  padding-left: 0px;
}
.phone-input{
  padding-right: 0px;
}

.contact-button-box{
  text-align: end;
}
.contact-button{
  background-color: var(--blue-color);
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 10px;
  height: 40px;
  cursor: pointer;
}
.contact-button:hover{
  opacity: 0.9;
}
.contact-button-text{
  list-style-type: none;
  color: var(--white-color);
  display: inline;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 17px;
  text-decoration: none;
  display: block;
  pointer-events: none;
}





/* FOOTER */

.footer {
  width: 100vw;
  height: 40vh;
  background-color: var(--dark-grey-color);
  text-align: center;
  padding: 20px;
}
.footer-seperator {
  width: 90%;
  margin: auto;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--grey-color);
}
.footer-text {
  color: var(--white-color);
  font-size: 15px;
}
.footer-text:hover{
  color: var(--blue-color);
  cursor: pointer;
}
.footer-sep {
  color: var(--white-color);
  font-size: 15px;
}
.footer-title{
  color: var(--light-grey-color);
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
}

.footer-list {
  list-style-type: none;
  padding-left: 36%;
}

.footer-item a {
  color: var(--white-color);
  display: block;
  text-decoration: none;
  font-size: 15px;
  padding-top: 16px;
  white-space: nowrap; 
  text-overflow: clip;
  text-align: left; 
}
.footer-item a:hover {
  color: var(--blue-color);
}
.social-icon{
  height: 30px;
  margin: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}


/* RESPONSIVE */

/* MOBILE */

@media screen and (max-width: 1700px) {
  .info-height{
    height: 220px;
  }
  .iphone-frame{
    left: 378px;
  }
  .carousel{
    left: 210px;
  }
}
@media screen and (max-width: 1400px) {
  .iphone-frame{
    left: 278px;
  }
  .carousel{
    left: 160px;
  }
}
@media screen and (max-width: 1200px) {
  .iphone-frame{
    left: 120px;
  }
  .carousel{
    left: 81px;
  }
}
@media screen and (max-width: 992px) {
  .navbar li {display: none;}
  .navbar li.hamburger-button {
    float: right;
    display: block;
    margin-right: 40px;
    margin-top: 17px;
  }
  .navbar-button{
    display: none;
  }
  h1{
    font-size: 40px;
    text-align: center;
  }
  h2{
    margin-top: 20px;
    font-size: 30px;
    text-align: center;
  }
  h4{
    font-size: 18px;
    text-align: center;
  }
  .header-text{
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  .header-text2{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-left: 10%;
    padding-right: 10%;
  }
  .header-form-group{
    justify-content: center;
  }
  .header-image-div{
    text-align: center;
  }
  .header-picture{
    padding-top: 5vh;
    justify-content: center;
    align-self: center;
    padding-left: 0px;
  }
  .info-card{
    margin-top: 20px;
  }
  .info-height{
    height: 310px;
  }
  .function-image-div{
    text-align: center;
    display: none;
  }
  .check-div{
    padding-left: 0px;
    padding-right: 0px;
  }
  .fa-check{
    padding-left: 0px;
  }
  .function-text{
    font-size: 18px;
    margin-left: -15px;
  }
  .price-card{
    margin-top: 20px;
  }
  .about-picture{
    display: none;
  }
  .about-title{
    text-align: center;
  }
  .about-text-row{
    padding-right: 0px;
  }
  .about-text{
    text-align: center;
  }
  .about-person-name{
    text-align: center;
  }
  .about-person-title{
    text-align: center;
  }
  .footer-list{
    padding-left: 20%;
  }
  .contact-us{
    margin-top: 20px;
  }
  .footer-seperator{
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .info-height{
    height: 190px;
  }
}


@media screen and (max-width: 500px) {
  .header-text{
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .header-text2{
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .name-input{
    padding-right: 0px;
    margin-bottom: 16px ;
  }
  .info-height{
    height: 220px;
  }
  .phone-input{
    padding-left: 0px;
  }
  .footer-list{
    padding-left: 0% !important;
  }
  .function-text{
    margin-left: 0px;
  }
}

@media screen and (max-width: 380px) {
  h6{
    padding-left: 3%;
    padding-right: 3%;
  }
  .header-picture{
    height: 70vw;
    min-height: auto;
  }
}