.order-content{
    margin-top:50px;
    padding-top: 10vh;
    background-color: var(--white-color);
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
}
.order-title-text{
    text-align: center;
}
.sub-header{
    text-align: left;
    color: var(--dark-gray-color);
    font-size: 22px;
}

@media screen and (max-width: 500px) {
    .order-content{
        margin-top:0px;
    }
}