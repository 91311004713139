.contact-content{
    padding-top: 10vh;
    background-color: var(--white-color);
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 20px;
}
.contact-title-text{
    text-align: center;
}
.contact-subhead-text{
    padding-left: 20vw;
    padding-right: 20vw;
    text-align: center;
}
.contact-info{
    margin-bottom: -15px;
}
.contact-card {
    width: 100%;
    background-color: var(--white-color);
    border-radius: 5px;
    box-shadow: 1px 1px 5px #888888;
    margin-top: 40px;
    text-align: center;
  }

.card-title{
    color: var(--dark-grey-color);
    font-size: 20px;
    margin-top: 30px;
    font-weight: bold;
    padding-top: 20px;
}
.card-text{
    color: var(--light-grey-color);
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    line-height: 1.3;
    padding-left: 2vw;
    padding-right: 2vw;
}
.card-col{
    padding-bottom: 10vh;
}
.contact-height{
    height: 140px;
}

@media screen and (max-width: 575px) {
    .contact-height{
        height: 125px;
    }
}

@media screen and (max-width: 500px) {
    .contact-content{
        margin-top:0px;
    }
    .contact-subhead-text{
        padding-left: 1vw;
        padding-right: 1vw;
        text-align: center;
    }
    .contact-card{
        margin-top: 10px;
    }
}